<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true">
      <img src="../../assets/loader.gif" />
    </vue-element-loading>
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="appConfirmation" width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          <span style="font-size:16px;font-weight:600">Would you like to delete this Category ?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#00a972" text @click="appConfirmation = false">Cancel</v-btn>
          <v-btn color="red" text @click="deleteRegion">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout wrap>
                <v-flex xs12 md12 text-center>
                  <span style="font-size:20px;font-weight:500;">Application Categories List</span>
                </v-flex>
              </v-layout>
              <v-layout wrap px-2 justify-center>
                <v-flex xs12 sm12 md12>
                  <v-card-title>
                    <v-layout wrap justify-space-around align="center">
                      <v-flex xs12 sm6 md3 pt-3>
                        <router-link to="/AddCategory">
                          <v-btn text color="#26af82">Add New Category</v-btn>
                        </router-link>
                      </v-flex>
                      <v-flex xs12 sm6 md3>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          color="#26af82"
                          label="Search"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card-title>

                  <v-data-table
                    :headers="headers"
                    :items="categories"
                    :search="search"
                    hide-default-footer
                    dense
                    :loading="appLoading"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                    color="#26af82"
                  >
                    <template v-slot:item._id="{ item }">
                      <v-layout>
                        <v-flex xs6 sm6 md6>
                          <v-btn
                            router-link
                            :to="'/EditCategory/'+item._id"
                            small
                            text
                            color="#26af82"
                            block
                          >edit</v-btn>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                          <v-btn @click="delModel(item)" small text color="red" block>Delete</v-btn>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      appConfirmation: false,
      appConfirmationOk: false,
      deleteId: null,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      search: null,
      headers: [
        {
          text: "Catgory Name",
          align: "left",
          sortable: true,
          value: "name"
        },
        { text: "Action", align: "center", value: "_id" }
      ],
      categories: []
    };
  },
  beforeMount() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/project/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          this.categories = response.data.data;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    delModel(event) {
      this.deleteId = event._id;
      this.appConfirmation = true;
    },
    deleteRegion() {
      this.appConfirmation = false;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/project/category/remove",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: {
          id: this.deleteId
        }
      })
        .then(response => {
          this.appLoading = false;
          this.getCategories();
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
.centered-input input {
  text-align: center;
  height: 20px;
}
</style>